import { Link, graphql } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
// import { CommentCount } from 'gatsby-plugin-disqus';

import Bio from '../components/Bio';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Panel from '../components/Panel';
import React from 'react';
import SEO from '../components/SEO';
import Pagination from '../components/Pagination';
import get from 'lodash/get';
import { rhythm } from '../utils/typography';

const BlogIndexTemplate = (props) => {
  const render = () => {
    const siteTitle = props.data.site.siteMetadata.title;
    const { langKey, numPages, currentPage } = props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPageLink =
      isFirst || currentPage - 1 === 1 ? '/' : `/page/${currentPage - 1}`;
    const nextPageLink = `/page/${currentPage + 1}`;

    const posts = props.data.allMarkdownRemark.edges;

    return (
      <Layout location={props.location} title={siteTitle}>
        <SEO />
        <main
          style={{
            flex: 1,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(28),
            marginTop: '6rem',
            padding: '0 24px',
            width: '100%',
          }}
        >
          {posts.map(({ node }) => {
            const title = get(node, 'frontmatter.title') || node.fields.slug;
            return (
              <article
                style={{ marginBottom: '2.5rem' }}
                key={node.fields.slug}
              >
                <header>
                  <h3
                    style={{
                      fontSize: rhythm(0.9),
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link
                      style={{ boxShadow: 'none' }}
                      to={node.fields.slug}
                      rel="bookmark"
                    >
                      {title}
                    </Link>
                  </h3>
                  <small
                    style={{
                      color: '#666',
                    }}
                  >
                    {formatPostDate(node.frontmatter.date, langKey)}
                    {` • `}{' '}
                    {/* <CommentCount
                      config={{
                        url: `https://blog.axiu.me${node.fields.slug}`,
                        identifier: node.id,
                        title,
                      }}
                      placeholder={'...'}
                    />
                    {` • `}{' '} */}
                    <span title="以每分钟265计算">
                      {formatReadingTime(node.timeToRead)}
                    </span>
                  </small>
                </header>
                <p
                  dangerouslySetInnerHTML={{ __html: node.frontmatter.spoiler }}
                />
              </article>
            );
          })}
          <Pagination
            isFirst={isFirst}
            isLast={isLast}
            currentPage={currentPage}
            numPages={numPages}
            prevPageLink={prevPageLink}
            nextPageLink={nextPageLink}
          ></Pagination>
        </main>
        <Footer />
      </Layout>
    );
  };
  return render();
};

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query($langKey: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          id
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
          }
        }
      }
    }
  }
`;
