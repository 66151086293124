import React from 'react';
import { rhythm } from '../utils/typography';

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(2),
        }}
      >
        <p
          style={{
            width: 310,
            color: '#ddd',
            borderBottom: '1px solid #ebeef5',
          }}
        ></p>
      </div>
    );
  }
}

export default Bio;
