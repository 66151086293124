import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './pagination.css';

const Pagination = ({
  isFirst,
  isLast,
  numPages,
  currentPage,
  prevPageLink,
  nextPageLink,
}) => {
  // hide if only one page
  if (numPages === 1) {
    return null;
  }
  return (
    <div className={'pagination'}>
      {!isFirst && (
        <Link to={prevPageLink} rel="prev">
          ← Prev
        </Link>
      )}
      {!isFirst && <span className={'separator'}>|</span>}
      {Array.from({ length: numPages }, (_, i) => {
        if (i + 1 === currentPage) {
          return <span key={'current'}>{currentPage}</span>;
        } else {
          return (
            <Link
              key={`pagination-number${i + 1}`}
              to={`${i === 0 ? '/' : `/page/${i + 1}`}`}
            >
              {i + 1}
            </Link>
          );
        }
      })}
      {!isLast && <span className={'separator'}>|</span>}
      {!isLast && (
        <Link to={nextPageLink} rel="next">
          Next →
        </Link>
      )}
    </div>
  );
};

Pagination.prototypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  prevPageLink: PropTypes.string,
  nextPageLink: PropTypes.string,
};

export default Pagination;
