import React from 'react';

import { rhythm } from '../utils/typography';
import ShapeHex from './shapeHex';

const Footer = () => {
  const render = () => {
    return (
      <footer
        style={{
          marginTop: rhythm(1.5),
          paddingTop: rhythm(1.5),
          paddingBottom: rhythm(1.5),
          fontFamily: 'initial',
          color: '#999',
          paddingLeft: '24px',
          paddingRight: '24px',
          background: 'var(--mainBg)',
          fontSize: rhythm(0.5),
        }}
      >
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(28),
            position: 'relative',
            textIndent: '18px',
          }}
        >
          <ShapeHex
            style={{
              width: 12,
              height: 7,
              background: '#888',
              left: -6,
              marginTop: '8px',
            }}
          ></ShapeHex>
          SideEffect is a blog for front-end web development. <br />
          <svg
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              fill: 'currentColor',
            }}
            viewBox="0 0 14 16"
            version="1.1"
            width="14"
            height="16"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M9.5 3L8 4.5 11.5 8 8 11.5 9.5 13 14 8 9.5 3zm-5 0L0 8l4.5 5L6 11.5 2.5 8 6 4.5 4.5 3z"
            ></path>
          </svg>{' '}
          Code by{' '}
          <a
            href="https://axiu.me"
            style={{ color: '#666' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Axiu
          </a>
          {' / '}
          <a
            href="/rss.xml"
            style={{ color: '#666' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            rss
          </a>
        </div>
      </footer>
    );
  };
  return render();
};

export default Footer;
